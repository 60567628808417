// istanbul ignore file
'use client';
import createCache from '@emotion/cache';
import type { Options } from '@emotion/cache/src/types';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@nrdy-marketing-engine/theming/src/blueshift-theme';
import { useServerInsertedHTML } from 'next/navigation';
import { useState } from 'react';

interface ThemeRegistryProps {
  children: React.ReactNode;
  options: Options & {
    baseline?: boolean;
    prepend?: boolean;
  };
}

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export default function ThemeRegistry(props: ThemeRegistryProps) {
  const { options, children } = props;

  const [{ cache, flush }] = useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: string[] = [];
    cache.insert = (...args) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    let styles = '';
    for (const name of names) {
      styles += cache.inserted[name];
    }
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: options.prepend ? `@layer emotion {${styles}}` : styles,
        }}
        data-emotion={`${cache.key} ${names.join(' ')}`}
        key={cache.key}
      />
    );
  });

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        {options.baseline && <CssBaseline />}
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}
