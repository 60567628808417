'use client';

import { basicLogger } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';

interface LaunchDarklyProviderProps {
  children: ReactNode;
}

function LaunchDarklyProvider({ children }: LaunchDarklyProviderProps) {
  return <>{children}</>;
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_REACT_APP_LD_KEY,
  options: {
    logger: basicLogger({ level: process.env.ENVIRONMENT === 'dv' ? 'debug' : 'error' }),
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(LaunchDarklyProvider) as typeof LaunchDarklyProvider;
