import { useEffect } from 'react';

const useUncaughtBrowserError = () => {
  useEffect(() => {
    const handleUnhandledRejections = (e: PromiseRejectionEvent) => {
      console.warn('UNHANDLED PROMISE REJECTION', e.reason);
      window['newrelic']?.addPageAction('PromiseRejectionEvent', {
        error: String(e.reason),
        stack: e.reason?.stack,
      });
      e.preventDefault();
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejections);
    return () => window.removeEventListener('unhandledrejection', handleUnhandledRejections);
  }, []);
};

export default useUncaughtBrowserError;
