'use client';

import { usePageLoadTracking, useScrollTracking } from '@nrdy-marketing-engine/tracking';
import useUncaughtBrowserError from '@nrdy-marketing-engine/tracking/src/use-uncaught-browser-error';

export default function Tracking() {
  useScrollTracking();
  usePageLoadTracking();
  useUncaughtBrowserError();
  return null;
}
