'use client';

import { useEffect } from 'react';

interface Newrelic {
  setCustomAttribute: (name: string, value: string | number | boolean) => void;
}

type Timing = Record<string, string | number>;

function setup() {
  const timing: Timing = { cache: 'none' };

  const getPerf = (p: string) =>
    (typeof performance.getEntriesByType === 'function' && performance.getEntriesByType(p)) || [];

  // bots are supposed to be filtered out of PageView table automatically; however Botify's user agent
  // (Mozilla/5.0 (compatible; botify; http://botify.com)) is undetected by NR and skews real user data
  // userAgentIsBot() returns boolean `isBot` value so that bots can be manually filtered out in NR charts
  function userAgentIsBot() {
    const pattern = /(bot|chuckler)/i;
    return pattern.test(navigator.userAgent);
  }

  function getServerTiming(): { description?: string; duration?: number; name: string }[] {
    if (document.cookie.indexOf('server_timing=') > -1) {
      const value = document.cookie.match(/server_timing=([^;]+)/)[1];
      return value.split(/,\s*/).map((item) => {
        return {
          name: item.match(/^([^:]+)/)[1],
          description: item.includes('desc=') ? item.match(/desc="?([^":]+)"?/)[1] : undefined,
          duration: item.includes('dur=') ? parseInt(item.match(/dur=(\w+)/)[1]) : undefined,
        };
      });
    } else {
      return getPerf('navigation')[0]?.['serverTiming'] || [];
    }
  }

  function setNewrelicAttribute(nr: Newrelic, name: string, value: string | number) {
    if (value !== undefined && value !== null) nr.setCustomAttribute(name, value);
  }

  function setNewrelicAttributes(newrelic: Newrelic, timing: Timing) {
    setNewrelicAttribute(newrelic, 'domInteractive', timing.domInteractive);
    setNewrelicAttribute(newrelic, 'domContentLoaded', timing.domContentLoaded);
    setNewrelicAttribute(newrelic, 'firstPaint', timing.firstPaintTime);
    setNewrelicAttribute(newrelic, 'firstContentfulPaint', timing.firstContentfulPaintTime);
    setNewrelicAttribute(newrelic, 'applicationTime', timing.applicationTime);
    setNewrelicAttribute(newrelic, 'originTime', timing.originTime);
    setNewrelicAttribute(newrelic, 'isBot', String(userAgentIsBot()));
    setNewrelicAttribute(newrelic, 'userAgent', navigator.userAgent);
    setNewrelicAttribute(newrelic, 'cache', timing.cache);
  }

  if (window.performance && typeof performance.timing === 'object') {
    const wpt = window.performance.timing;

    // HTML fully parsed
    timing.domInteractive = (wpt.domInteractive - wpt.navigationStart) / 1000.0;

    // DOM + CSSOM complete; synchronous, async and defer JS parsed and executed
    timing.domContentLoaded = (wpt.domContentLoadedEventEnd - wpt.navigationStart) / 1000.0;

    // performance.getEntriesByType has limited browser support
    for (const perf of getPerf('paint')) {
      const name = perf.name.replace(/-(\w)/g, (_, m) => m.toUpperCase()) + 'Time';
      timing[name] = perf.startTime / 1000;
    }

    // parse Server-Timing cookie or response header to get cache status
    for (const st of getServerTiming()) {
      if (st.name === 'cache') timing.cache = st.description;
      if (st.name === 'cdn-cache-hit') timing.cache = 'hit-cdn';
      if (st.name === 'cdn-downstream-fbl') timing.applicationTime = st.duration / 1000.0;
      if (st.name === 'cdn-upstream-fbl') timing.originTime = st.duration / 1000.0;
    }

    // New Relic
    const newrelic = window['newrelic'] as Newrelic;
    if (newrelic) setNewrelicAttributes(newrelic, timing);
  }
}

// TODO: Define our browser support level and update this function accordingly
// This is currently copied from mex_components
function errorFiltering() {
  function unsupportedBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Firefox/')) {
      return parseInt(navigator.userAgent.match(/Firefox\/([\d\.]+)/)[1]) < 31;
    } else if (userAgent.includes('Edg/')) {
      return parseInt(navigator.userAgent.match(/Edg\/([\d\.]+)/)[1]) < 16;
    } else if (userAgent.includes('Chrome/')) {
      return parseInt(navigator.userAgent.match(/Chrome\/([\d\.]+)/)[1]) < 60;
    } else if (userAgent.includes('Safari/')) {
      return parseInt(navigator.userAgent.match(/Version\/([\d\.]+)/)[1]) < 11;
    } else {
      return true;
    }
  }

  window['newrelic'] &&
    window['newrelic'].setErrorHandler(function (err: Error) {
      return unsupportedBrowser() || Boolean(err.stack?.includes('chrome-extension'));
    });
}

export default function NewrelicSetup() {
  useEffect(setup, []);
  useEffect(errorFiltering, []);
  return null;
}
