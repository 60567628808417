import novaAurora from '@blueshift-ui/theme/dist/themes/nova-aurora';
import tokens from '@blueshift-ui/tokens/dist/nova-aurora';
import { Theme, createTheme } from '@mui/material';
import { screens } from './screens';

const t = novaAurora.theme;
const tc = t.components;

const blueshiftTheme: Theme = createTheme({
  ...t,
  typography: {
    ...t.typography,
    fontFamily: 'var(--font-sans, HelveticaNeue)',
    body1: {
      ...t.typography.body1,
      fontFamily: 'var(--font-sans, HelveticaNeue)',
    },
    h1: {
      ...t.typography.h1,
      fontFamily: 'var(--font-sans, HelveticaNeue)',
    },
    h2: {
      ...t.typography.h2,
      fontFamily: 'var(--font-sans, HelveticaNeue)',
    },
  },
  components: {
    ...tc,
    MuiAlert: {
      ...tc.MuiAlert,
      styleOverrides: {
        root: {
          ...(tc.MuiAlert.styleOverrides.root as any),
          fontFamily: 'var(--font-sans, HelveticaNeue)',
        },
      },
    },
    MuiMenu: {
      ...tc.MuiMenu,
      styleOverrides: {
        root: {
          ...(tc.MuiMenu.styleOverrides.root as any),
          '.MuiList-root': {
            // cancel overrides in blueshift
          },
          '.MuiMenu-paper': {
            borderRadius: tokens.sys.shape.corner.extraSmall,
            maxHeight: 'calc(min(100% - 96px, 310px))',
          },
        },
      },
    },
    MuiDrawer: {},
    MuiCssBaseline: {
      ...tc.MuiCssBaseline,
      styleOverrides: {
        ...(typeof tc.MuiCssBaseline.styleOverrides === 'object'
          ? tc.MuiCssBaseline.styleOverrides
          : {}),
        'li::marker': {},
      },
    },
  },
  breakpoints: {
    values: {
      xs: parseInt(screens.xs),
      sm: parseInt(screens.sm),
      md: parseInt(screens.md),
      lg: parseInt(screens.lg),
      xl: parseInt(screens.xl),
    },
  },
  palette: {
    ...t.palette,
    sapphire: {
      main: '#4a48c6',
      light: '#5C5ACC',
      dark: '#4a48c6',
      contrastText: 'white',
    },
    amethyst: {
      main: '#6F4A9D',
      light: '#7D5CA7',
      dark: '#6F4A9D',
      contrastText: 'white',
    },
  },
});

export default blueshiftTheme;
